.project-grid {

    display: grid;
    grid-column-gap: $unit-1;
    grid-template-columns: repeat(16, 1fr);

    > * {
        min-width: 0;
    }
}

.project-body-column {
    grid-column: 5 / 13;
}