/*
//  Use spacing classes on divs that need
//  padding on both sides. Adjacent spacers
//  have their top padding removed.
*/

.section-spacer {

    padding-top: $section-spacer;
    padding-bottom: $section-spacer;

    &--margin {
        margin-top: $section-spacer;
        margin-bottom: $section-spacer;
    }
}

.group-spacer {

    padding-top: $group-spacer;
    padding-bottom: $group-spacer;
}

.group-spacer + .group-spacer {

    padding-top: 0;
}

.group-spacer:last-child {

    padding-bottom: 0;
}

.space-after {

    margin-bottom: 1em;
}

.space-before {

    margin-top: 1em;
}

/*
//  Use prefix and postfix classes
//  to add and remove margins to
//  text elements.
//
//  Examples: 
//  .sp-pos-0 removes space after the element
//  .sp-pos-2 adds two spaces after the element
//  .sp-pre-1 adds one space before the element
*/

@for $i from 0 to 8 {

    .sp-pre-#{nth($i, 1)} {

        margin-top: nth($i, 1) * 20px;
    }

    .sp-pos-#{nth($i, 1)} {

        margin-bottom: nth($i, 1) * 20px;
    }

    .sp-#{nth($i, 1)} {

        margin-bottom: nth($i, 1) * 20px;
        margin-top: nth($i, 1) * 20px;
    }
}
