.shadow {

    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.shadow--high {

    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.1);
}

.shadow--low {

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}