// Global

html {
    line-height: 0;
}

body, html {

    width: 100%;
    height: 100%;
}

body {

    font-family: $fontStack;
    font-weight: 300;
    font-size: 100%;
    background-color: #FFF;
    @extend .text-base;
}

.overflow-hidden {

    overflow: hidden;
}

a {
    font-weight: 500;
    text-decoration: none;
}

a:link {

    color: inherit;
}

a:visited {
    color: inherit;
}

a:hover {
    @extend .text-highlight;
}

a:active {
    color: $darkHighlight !important;
}