.project-card {

}

.project-card__container {

    @extend .shadow;

    display: block;
    border-radius: 6px;
    overflow: hidden;
    text-decoration: none;
    transition: all 0.3s;
}

.project-card--flat .project-card__container {
    box-shadow: none;
}

.project-card__artwork {

    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;

    img {
        margin: 0 auto;
        display: block;
        transition: all 0.3s;

        @media (max-width: 500px) {
            height: 60vh;
        }
    }
}

.project-card__shader {

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 270px;
    display: block;
    opacity: 0.25;
}

.project-card__caption {

    padding: $unit-2 $unit-3;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    margin: $unit-1 * -1;
    & > * {
        margin: $unit-1
    }

    @media (max-width: 1400px) {
        padding-left: 5%;
        padding-right: 5%;
    }
}

// Interactions

a.project-card__container:active {

    @extend .shadow--low;
    transform: translate(0,0);
    transition: all 0.15s;

    .project-card__artwork img {

        transform: scale(1.05);
    }
}

a.project-card__container:hover {

    @extend .shadow--high;
    transform: translate(0,-10px);
    color: inherit;

    .project-card__artwork img {

        transform: scale(1.05);
    }
}