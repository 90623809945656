.video {

    position: relative;
    cursor: pointer;
    overflow: hidden;

    video {
        width: 100%;
    }

    &__play-icon-wrapper {
        width: 100px;
        height: 100px;
        background: #FFF;
        border-radius: 50px;
        position: absolute;
        bottom: 55%;
        left: 50%;
        margin: 0 0 -50px -50px;
        display: block;
        overflow: visible;
        
        transition: all 0.3s;
        @extend .shadow;

        path {
            transition: all 0.3s;
        }

        #Pause {
            opacity: 0;
        }
    }

    &:hover &__play-icon-wrapper  {

        @extend .shadow--high;

        path {
            fill: $highlight;
        }
    }

    &--is-playing {

        .video__play-icon-wrapper {
            margin: 0;
            left: 2%;
            bottom: 2%;
            transform: scale(0.6);

            path {
                fill: $highlight;
            }

            #Pause {
                opacity: 1;
            }

            #Play {
                opacity: 0;
            }
        }
    }
}