.modal {

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    display: none;

    &__cover {

        position: absolute;
        width: 100%;
        height: 100%;
        background: $black;
        opacity: 0.8;
    }

    &__window {

        @extend .shadow;

        position: relative;
        max-width: 600px;
        padding: $section-spacer;
        background: #FFF;
        border-radius: 6px;

        @media (max-width: 600px) {

            border-radius: 0;
            padding: $unit;
            height: 100vh;
            width: 100%;
        }
    }
}

.modal--open {

    display: flex;
}