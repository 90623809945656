.project-next {

    @extend .container;
    @extend .section-spacer--margin;
    text-align: center;
}

.project-next__action {

    display: inline-block;
    margin-top: $unit-1;
}