.text-base {

    color: $black;
}

.text-highlight {

    color: $highlight;
}

.text-secondary {

    color: $secondary;
}

.text-secondary-reversed {

    color: $secondaryReversed;
}

.text-error {

    color: #CC0000;
}