// Variables

// Screens
$screen-xs:         480px;
$screen-xs-min:     $screen-xs;
$screen-phone:      $screen-xs-min;

$screen-sm:         768px;
$screen-sm-min:     $screen-sm;
$screen-tablet:     $screen-sm-min;

$screen-md:         992px;
$screen-md-min:     $screen-md;
$screen-desktop:    $screen-md-min;

$screen-lg:         1200px;
$screen-lg-min:     $screen-lg;
$screen-lg-desktop: $screen-lg-min;

$screen-xs-max:     ($screen-sm-min - 1);
$screen-sm-max:     ($screen-md-min - 1);
$screen-md-max:     ($screen-lg-min - 1);

// Fonts
$fontStack: "Univers LT", Univers, "Helvetica Neue", Helvetica, Arial, sans-serif;

// Spacing
$unit: 20px;
$unit-1: $unit;
$unit-2: $unit * 2;
$unit-3: $unit * 3;
$unit-4: $unit * 4;
$unit-5: $unit * 5;
$unit-6: $unit * 6;
$unit-7: $unit * 7;
$unit-8: $unit * 8;

$section-spacer: 90px;
$group-spacer: 60px;

// Colors
$black: #333333;
$highlight: #3EBA7E;
$darkHighlight: darken( $highlight, 10% );
$secondary: #757575;
$secondaryReversed: #9C9C9C;


// $primary:#3C92F6;
// $dark:#2977D2;
// $green: #82CE66;
// $light: #F5F5F5;
// $border-color: #D7D7D7;

// // Anchor Links
// $link-decoration: underline;

// // Buttons
// $btn-padding-x-lg: 50px;
// $btn-border-radius: 2px;
// $btn-border-radius-lg: 2px;
// $btn-border-radius-sm: 2px;
// $btn-font-weight: 400;
// $btn-border-width: 2px;


// // Colors
// $primaryHighlight: #3C92F6;
// $primaryHighlightDark: #2977D2;
// $secondaryHighlight: #82CE66;
// $textColor: #5D5D5D;
// $lightSectionColor: #F5F5F5;



// $blue: #3C92F6;
// $darkBlue: #2977D2;
// $lightGrey: #F4F4F4;
// $darkGrey: #585858;
// $text: #5D5D5D;
// $orange: #FFA519;
