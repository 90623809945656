h1 {

    font-family: $fontStack;
    font-weight: 600;
    font-style: normal;
    font-size: 24px;
    line-height: 133%;
    margin: 0;
    
}

h2 {

    font-family: $fontStack;
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    line-height: 133%;
    margin: 0;
    
}

h3 {

    font-family: $fontStack;
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    line-height: 120%;
    margin: 0;
    
}

h4 {

    font-family: $fontStack;
    font-weight: 400;
    font-style: normal;
    font-size: pem(30.0);
    line-height: pem(42.0, 30.0);
    margin-top: pem(42.0, 30.0);
}

h5 {

    font-family: $fontStack;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    margin: 0;
    
}

h6 {

    font-family: $fontStack;
    font-weight: 400;
    font-style: normal;
    font-size: pem(16.0);
    line-height: pem(24.0, 16.0);
    margin-top: pem(12.0, 16.0);
}

p, li {

    font-family: $fontStack;
    font-weight: 300;
    font-style: normal;
    font-size: 20px;
    line-height: 160%;
    margin: 0;
    
}

ul {
    padding-left: 0;
    list-style: square url('../images/Bullet.svg');
}

b, strong {
    font-weight: 500;
}

.align--left {
    text-align: left;
}

.align--center {
    text-align: center;
}

.align--right {
    text-align: right;
}

// @include media-breakpoint-down(sm) {
//     html {
//       font-size: 0.8rem;
//     }
// }