.project-header {

    @extend .container;
    @extend .project-grid;

    margin-top: $unit-2;
    margin-bottom: $section-spacer;

    &__action {
        grid-column-start: span 3;
    }

    &__title {
        @extend .project-body-column;
    }

    @media (max-width: 1090px) {

        margin-bottom: $group-spacer;

        &__action, &__title {
    
            grid-column: 1 / -1;
        }

        &__title {

            order: -1;
        }

        &__action {

            margin-top: $group-spacer;
        }
    }
}