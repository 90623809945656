.footer {

    @extend .container--full;

    margin-top: $section-spacer;
    background: $black;
    color: #FFF;
}

.footer__container {

    @extend .container;
    @extend .section-spacer;
    padding-bottom: $unit-1;
}

.footer__details {

    padding-top: $unit-4;
    
    display: grid;
    grid-column-gap: $unit-2;
    grid-row-gap: $unit-2;
    grid-template-columns: repeat(6, 1fr);

    > * {
        min-width: 0;
    }

    p {
        grid-column-start: span 3;
    }

    dl {

        grid-column-start: span 1;
    }

    @media (max-width: 1040px) {

        p {
            grid-column-start: span 6;
        }
    
        dl {
    
            grid-column-start: span 2;
        }
    }

    @media (max-width: 600px) {

        dl {
    
            grid-column-start: span 6;
        }
    }
}

.footer__disclosures {

    padding-top: $unit-4;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    @extend .text-secondary-reversed;

    a {
        color: #FFF;
    }

    a:hover {
        @extend .text-highlight;
    }
}