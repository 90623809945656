.project-card-list {

    @extend .container;

    display: grid;
    grid-column-gap: $unit-2;
    grid-row-gap: $section-spacer;
    grid-template-columns: repeat(2, 1fr);

    &__item {
        grid-column-start: span 2;
        min-width: 0;
    }

    &__item--narrow {
    
        grid-column-start: span 1;
        min-width: 0;

        @media (max-width: 1400px) {
            grid-column-start: span 2;
        }
    }
}

.project-card-list__item {
    @extend .scroll-reveal;
}

.sr .project-card-list__item {
    @extend .scroll-reveal--hide;
}