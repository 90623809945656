// Overflow

.ov-hidden {

    overflow: hidden;
}

// Dictionaries

dl {

    @extend .sp-pre-0;
    @extend .sp-pos-1;
}

dt, dd {

    @extend p;
    margin-bottom: 7px;
    line-height: 120%;
}

dt {
    // Offset to line up with the grid after
    // the line-height adjustment for list items
    margin-top: 4px;
}

// Lists

.hang {

    padding-left: 0;
}

// Containers

.container {

    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    max-width: 1700px;

    &--full {

        margin-left: 0px;
        margin-right: 0px;
    }
}

.container--wide {
    margin-left: 20px;
    margin-right: 20px;
}