@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=9bc8d29d-3dd2-48d4-92e6-befbd3dce372");

// Univers LT Light 45
@font-face{
    font-family:"Univers LT";
    font-style: normal;
    font-weight: 300;
    font-display: auto;
    src:url("../fonts/a91ff084-fc8b-43f4-b0ea-111254b68524.eot?#iefix");
    src:url("../fonts/a91ff084-fc8b-43f4-b0ea-111254b68524.eot?#iefix") format("eot"),url("../fonts/9fa24f1f-00d8-4d28-910f-78e925eaead6.woff2") format("woff2"),url("../fonts/d64e7bbc-e9ea-46ad-982c-6195ae4a3c61.woff") format("woff"),url("../fonts/d270a34d-b6b8-4c07-8454-03a6a3db7603.ttf") format("truetype");}

// Univers LT Light Italic 45
@font-face{
    font-family:"Univers LT";
    font-style: italic;
    font-weight: 300;
    font-display: auto;
    src:url("../fonts/2f2779cd-9c70-4724-b9b0-c88961257db4.eot?#iefix");
    src:url("../fonts/2f2779cd-9c70-4724-b9b0-c88961257db4.eot?#iefix") format("eot"),url("../fonts/aeb5cbb5-2077-4a57-88d8-1cc1e9c804e8.woff2") format("woff2"),url("../fonts/26832c6c-7609-41a6-b856-151be500486b.woff") format("woff"),url("../fonts/c78521a7-2342-410a-a275-e6d35b853313.ttf") format("truetype");}

// Univers LT Roman 55
@font-face{
    font-family:"Univers LT";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src:url("../fonts/6510377f-160a-4f66-aec0-2b71f05e9736.eot?#iefix");
    src:url("../fonts/6510377f-160a-4f66-aec0-2b71f05e9736.eot?#iefix") format("eot"),url("../fonts/5c8d59c2-9c85-4a22-88e3-bf3fba01dcd8.woff2") format("woff2"),url("../fonts/fc51d03b-0d7d-45df-9415-3c6270c80749.woff") format("woff"),url("../fonts/74bdff6d-c5de-42e8-a4d8-11ea14eddb22.ttf") format("truetype");}

// Univers LT Roman Italic 55
@font-face{
    font-family:"Univers LT";
    font-style: italic;
    font-weight: 400;
    font-display: auto;
    src:url("../fonts/541dccf9-50f5-47bd-9ba9-a01fd9a3792b.eot?#iefix");
    src:url("../fonts/541dccf9-50f5-47bd-9ba9-a01fd9a3792b.eot?#iefix") format("eot"),url("../fonts/5bfff3ac-e569-450d-918b-d564434e5e24.woff2") format("woff2"),url("../fonts/05d2bfe7-45b3-4cf8-b313-290651409c59.woff") format("woff"),url("../fonts/33db2509-f09f-4704-bb16-a90dd82687f7.ttf") format("truetype");}

// Univers LT Bold 65
@font-face{
    font-family:"Univers LT";
    font-style: normal;
    font-weight: 500;
    font-display: auto;
    src:url("../fonts/38dc895e-c366-4a3e-b212-a84a2d07a329.eot?#iefix");
    src:url("../fonts/38dc895e-c366-4a3e-b212-a84a2d07a329.eot?#iefix") format("eot"),url("../fonts/8a711a0d-3f17-4bfe-887b-6229858332b3.woff2") format("woff2"),url("../fonts/05a79c4b-9e9d-46e4-9d4b-610be05371c4.woff") format("woff"),url("../fonts/4e00405b-a96d-4972-bdff-2d55e1066c45.ttf") format("truetype");}

// Univers LT Bold Italic 65
@font-face{
    font-family:"Univers LT";
    font-style: italic;
    font-weight: 500;
    font-display: auto;
    src:url("../fonts/dca67ca5-170f-48b1-91ac-98def05d62bf.eot?#iefix");
    src:url("../fonts/dca67ca5-170f-48b1-91ac-98def05d62bf.eot?#iefix") format("eot"),url("../fonts/cc843e1a-cf1f-47e9-a03f-ef03d2b85d9a.woff2") format("woff2"),url("../fonts/653a9f79-b3ad-444c-a6c4-6bf7f1112e6e.woff") format("woff"),url("../fonts/59d447a0-a4b6-478d-b071-2c949371dca6.ttf") format("truetype");}

// Univers LT Black 75
@font-face{
    font-family:"Univers LT";
    font-style: normal;
    font-weight: 600;
    font-display: auto;
    src:url("../fonts/e5fb72ca-12c4-49f5-9253-653474f25a27.eot?#iefix");
    src:url("../fonts/e5fb72ca-12c4-49f5-9253-653474f25a27.eot?#iefix") format("eot"),url("../fonts/9d846738-13de-43dd-a3f2-ff53924f926c.woff2") format("woff2"),url("../fonts/ea880276-dfca-4a2a-a7ce-3d597e85112a.woff") format("woff"),url("../fonts/a0361fe2-856b-41d4-a5e3-e87579315974.ttf") format("truetype");
}

// Univers LT Black Italic 75
@font-face{
    font-family:"Univers LT";
    font-style: italic;
    font-weight: 600;
    font-display: auto;
    src:url("../fonts/7273961f-0fd2-454d-b5c1-d21fbc14df0f.eot?#iefix");
    src:url("../fonts/7273961f-0fd2-454d-b5c1-d21fbc14df0f.eot?#iefix") format("eot"),url("../fonts/5c2dd742-d755-4a5c-9572-57155d927f40.woff2") format("woff2"),url("../fonts/9246bc5d-e032-4efe-99fc-a0c3f26f6cdc.woff") format("woff"),url("../fonts/f2a63a77-fca8-4306-afbf-8e1860fa97b0.ttf") format("truetype");}


