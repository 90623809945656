.login {

    &__password {

        width: 100%;
    }

    &__actions {

        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: baseline;
    }

    &__error {

        @extend .text-error;
        margin-top: $unit;
    }

    @media (max-width: 600px) {

        margin-top: 50%;

        &__actions {

            flex-direction: column-reverse;
            align-items: stretch;

            & > * {

                margin-bottom: $unit;
            }
        }
    }
}