.crash {

    @extend .container;

    &__container {

        background: #f0f0f0;
        min-height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 6px;
        text-align: center;
    }

    &__title {

        font-size: 48px;
    }
}