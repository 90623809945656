.btn {

    @extend h5;
    @extend strong;

    transition: color 0.1s;
    text-decoration: none;
    position: relative;
    cursor: pointer;
}

.btn--forward {

    padding-right: 32px + 12px;

    &::after {

        content: " ";
        display: block;
        position: absolute;
        width: 32px;
        height: 21px;
        right: 0;
        top: 0;
        margin-top: 2px;
        transition: right 0.15s;
        background: url('../images/Button-Arrow-Right.svg');
    }

    &:hover::after {

        right: -10px;
    }
}

.btn--backwards {

    padding-left: 32px + 12px;

    &::after {

        content: " ";
        display: block;
        position: absolute;
        width: 32px;
        height: 21px;
        left: 0;
        top: 0;
        margin-top: 2px;
        transform: scale(-1);
        transition: left 0.15s;
        background: url('../images/Button-Arrow-Right.svg');
    }

    &:hover::after {

        left: -10px;
    }
}

.btn--primary {

    background: $highlight;
    border: none;
    padding: ($unit-1/2) $unit-2;
    display: block;
    border-radius: 6px;
    transition: all 0.15s;

    &:hover {
        @extend .shadow;
        transform: scale(1.05);
    }
}

.btn--disabled {

    @extend .text-secondary;
}