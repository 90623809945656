.scroll-reveal {

    transition-property: transform, opacity;
    transition-duration: 1000ms;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
}

.scroll-reveal--hide {
    opacity: 0;
    transform: translate(0, 100px);
}

.sr .scroller-reveal--display {
    opacity: 1;
    transform: translate(0,0);
}