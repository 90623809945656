.project-story {

    @extend .container;
    @extend .project-grid;

    p, h2, h3, ul {
        @extend .project-body-column;
        @extend .space-after;

        @media (max-width: 1090px) {

            grid-column: 1 / -1;
        }
    }

    h2, h3, ul, li {
        @extend .space-before;
    }

    img, .video {
        border-radius: 6px;
        width: 100%;
    }

    picture, .video {
        grid-column: 1 / -1;
        width: 100%;
        margin: $section-spacer 0;

        & + h6 {
            grid-column: 1 / -1;
            margin-top: -$section-spacer + $unit-1;
            margin-bottom: $section-spacer;
        }

        // Tightens spacing when we multiple images and/or videos
        // back-to-back
        & + .video,
        & + picture,
        & + h6 + .video,
        & + h6 + picture {

            margin-top: 0;
        }
    }

    h6 {
        color: $secondary;
    }

    @media (max-width: 1090px) {

        ul {
            margin-left: $unit;
        }
    }
}

.project-story > *  {
    @extend .scroll-reveal;
}

.sr .project-story > *  {
    @extend .scroll-reveal--hide;
}

.sr .project-story > h6  {
    transform: translate(0,0);
}